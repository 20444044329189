/** @jsx jsx */
import React, { useEffect, useState } from "react";
import { Container, jsx, Button } from "theme-ui";
import "../../../css/account.css";
import Spinner from "react-bootstrap/Spinner";
import PrimaryLayout from "../../../components/layout/primary/primary";
import AccountTopMenu from "../../../components/top-menus/account/account-top-menu";
import { validateJwtToken, getJwtTokenRoles } from "../../../services/auth-service";
import Pagination from "../../../components/pagination/pagination";
import { getOrders } from "../../../services/order-service";
import { navigate } from "gatsby";
import DatePicker from "../../../components/date-picker/date-picker";
import {Formik } from "formik";
import { formatPrice, applyVAT } from '../../../helper/product-helper';

const ProductVatPage: React.FunctionComponent = ({ }: any) => {
	if (!validateJwtToken()) return null;

	const [orders, setOrders] = useState([]);
	const [searchTerm, setSearchTerm] = useState("");
	const [updating, setUpdating] = useState(false);
	const [loading, setLoading] = useState(false);
	const [checkedState, setCheckedState] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [recordsPerPage] = useState(10);
	const [nPages, setNPages] = useState(0);
	const [totalRecords, setTotalRecords] = useState(0);
	const [currentRecords, setCurrentRecords] = useState(0);
	const [start, setStart] = useState(1);
	const [end, setEnd] = useState(11);
	const [message, setMessage] = useState('');
	const [error, setError] = useState(false);
	const [total, setTotal] = useState(0);
	const [totalProductVAT, setTotalProductVAT] = useState("");
	const [pagedProductVAT, setPagedProductVAT] = useState("");
	const [pagedTotalPrice, setPagedTotalPrice] = useState("");

	const [fromDateValue, setFromDateValue] = useState<Date | undefined>(undefined);
	const [toDateValue, setToDateValue] = useState<Date | undefined>(undefined);

	const initialFormValues = {
		fromDate: fromDateValue,
		toDate: toDateValue
	};

	useEffect(() => {
		setLoading(true);

		const roles = getJwtTokenRoles();
		if (!roles.includes("FullAccess") && !roles.includes("ViewOrders")) {
			navigate('/401');
		}
		else {
			const fetchData = async () => {
				await getOrdersAsync();
				setLoading(false);
			};
			fetchData();
		}
	}, []);

	const getOrdersAsync = async (page?: any, start?: any, end?: any) => {
		let pageNumber = page;
		if (currentPage == 1 && page == undefined) {
			pageNumber = 0;
		}

		await getOrders(pageNumber != undefined ? pageNumber : currentPage, recordsPerPage, searchTerm, start, end, 2).then((result) => {
			if (result.status === 200) {
				let data = result.data.orders;
				let total = parseInt(result.data.count);
				setTotalProductVAT(formatPrice(result.data.totalProductVAT))

				setTotalRecords(Number.isNaN(total) ? 0 : total);
				setOrders(data);

				const indexOfLastRecord = currentPage * recordsPerPage;
				const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
				const currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);
				const pages = Math.ceil(total / recordsPerPage);
				setCurrentRecords(currentRecords);
				setNPages(pages);

				setCheckedState(new Array(data.length).fill(false));

				setTotal(Math.round(calculateTotal(data)));
			} else {
				setError(true);
				setMessage(result);
			}
		});
	};

	const calculateTotal = (data: any) => {
		let total = 0;
		let vat = 0;
		data.forEach(order => {
			order.orderItems.forEach(item => {
				total += item.price * item.quantity + item.deliveryCharge;
				vat += item.price * item.quantity * (item.vat / 100);
			});
		});
		setPagedProductVAT(vat);
		setPagedTotalPrice(total);
		return total + vat;
	  };

	const handleSelectedPagination = async (currentPage?: any, page?: any) => {
		setLoading(true);

		if (page !== undefined && page > 1) {
			await getOrdersAsync(page);
			setCurrentPage(page);
			setStart(page);
			setEnd(page + 10);
		}
		else {
			await getOrdersAsync(currentPage);
			setStart(currentPage);
			setEnd(currentPage + 10);
		}
		setLoading(false);
	}

	const TableCountLabel = () => {
		const text = loading ? "Fetching" : "Showing";

		return (
			<React.Fragment>
				{text} {" "}
				{(currentPage == 1 ? 1 : (currentPage - 1) * 10 + 1)}
				{" "}-{" "}
				{currentPage == 1 ? 10 : currentPage * 10} of {totalRecords}
			</React.Fragment>
		);
	}

	const setDateFromCallback = (date: string) => {
		if (date)
			setFromDateValue(new Date(date));
	}

	const setDateToCallback = (date: string) => {
		if (date)
			setToDateValue(new Date(date));
	}

	const handleFilter = async () => {
		setLoading(true);
		await getOrdersAsync(0, fromDateValue, toDateValue);
		setLoading(false);
	}

	return (
		<PrimaryLayout topMenuChild={AccountTopMenu}>
			<Container py="40px" sx={styles.container}>
				<div className="main-wrapper">
					<div className="header" sx={styles.header}>
						<h1>Product VAT Report</h1>
						<hr className="bar-indent-center" />
					</div>

					<Formik
						initialValues={initialFormValues}
						enableReinitialize={true}
						onSubmit={async (values, { setSubmitting }) => {
						}}>
						{(formik: any) => {
							const { setFieldTouched } = formik;

							return (
								<div className="row">
									<div className="col-lg-3 col-md-3">
										<div className="form-group">
											<label htmlFor="fromDate">
												From
											</label>
											<DatePicker
												name="fromDate"
												id="fromDate"
												className="form-control"
												dateChangeCallback={setDateFromCallback}
												setFieldTouchedCallback={setFieldTouched}
											/>
										</div>
									</div>

									<div className="col-lg-3 col-md-3">
										<div className="form-group">
											<label htmlFor="toDate" >
												To
											</label>
											<DatePicker
												name="toDate"
												id="toDate"
												className="form-control"
												dateChangeCallback={setDateToCallback}
												setFieldTouchedCallback={setFieldTouched}
											/>
										</div>
									</div>

									<div className="col-lg-4 col-md-4">
										<Button sx={styles.filterButton}
											onClick={() => handleFilter()}
											disabled={loading}>
											Filter
										</Button>
									</div>
								</div>
							)
						}}
					</Formik>

				</div>

				<div className="col-md-7">
					<p sx={styles.tableCountLabel}>
						<TableCountLabel />
					</p>
				</div>
	
				<div className="wrapper" sx={styles.tableWrapper}>

					{!loading ? (
						<React.Fragment>
							<b>Total Product VAT: {totalProductVAT}</b>

							<table
								className="table"
								sx={styles.table}
								role="table"
								id="ordersTable"
							>
								<thead role="rowgroup" id="ordersTable">
									<tr role="row" id="ordersTable">
										<th>Order Id</th>
										<th>Product</th>
										<th className="text-center">Quantity</th>
										<th className="text-center">Created</th>
										<th className="text-center">Status</th>

										<th>
											<span sx={styles.floatRight}>
												Price
											</span>
										</th>
										<th>
											<span sx={styles.floatRight}>
												VAT (£)
											</span>
										</th>
										<th>
											<span sx={styles.floatRight}>
												VAT (%)
											</span>
										</th>
										<th>
											<span sx={styles.floatRight}>
												Total
											</span>
										</th>
									</tr>
								</thead>
								<tbody role="rowgroup" id="ordersTable">
								<React.Fragment>
									{orders.map((order, index) => (
										<tr role="row">
											<td role="cell">{order.id}</td>
											<td role="cell">
												{order.orderItems.map((item: any) => (
													<span>{item.name}<br /></span>
												))}
											</td>
											<td className="text-center">
												{order.orderItems.map((item: any) => (
													<span>{item.quantity}<br /></span>
												))}
											</td>
											<td className="text-center">{order.date}</td>
											<td role="cell" className="text-center">
												<span>
													{order.status}
												</span>
											</td>
											<td>
												<span>
													{order.orderItems.map((item: any) => (
														<React.Fragment>
															<span sx={styles.floatRight}>{formatPrice(item.price)}</span><br />
														</React.Fragment>
													))}
												</span>
											</td>
											<td>
												<span>
													{order.orderItems.map((item: any) => (
														<React.Fragment>
															<span sx={styles.floatRight}>{formatPrice(item.totalVat)}</span><br/>
														</React.Fragment>
													))}
												</span>
											</td>
											<td>
												<span>
													{order.orderItems.map((item: any) => (
														<React.Fragment>
															<span sx={styles.floatRight}>{item.vat}%</span><br/>
														</React.Fragment>
													))}
												</span>
											</td>
											<td>
												<span >
													{order.orderItems.map((item: any) => (
														<React.Fragment>
															<span sx={styles.floatRight}>{formatPrice(applyVAT(item.price, item.vat))}</span><br />
														</React.Fragment>
													))}
												</span>
											</td>
										</tr>
									)
									)}
										<tr>
											<td colSpan={6}><b sx={styles.floatRight}>{formatPrice(pagedTotalPrice)}</b></td>
											<td colSpan={1}><b sx={styles.floatRight}>{formatPrice(pagedProductVAT)}</b></td>
											<td colSpan={3}><b sx={styles.floatRight}>{formatPrice(total)}</b></td>
										</tr>
									</React.Fragment>
								</tbody>
							</table>

							<div className="col-md-12">
								<div className="row">
									<div className="col-md-3">
										{/* <Button sx={styles.exportButton}>
											{!updating && <span></span>}
											{updating && (
												<Spinner animation="border" role="status">
													<span className="sr-only"></span>
												</Spinner>
											)}
											Export to CSV
										</Button> */}
									</div>
									<div className="col-md-9">
										<p style={{ float: "right" }}>
											<TableCountLabel />
										</p>
									</div>
								</div>
							</div>

							<div className="col-md-12" style={{ marginTop: '20px' }}>
								<Pagination
									nPages={nPages}
									currentPage={currentPage}
									setCurrentPage={setCurrentPage}
									handleSelectedPagination={handleSelectedPagination}
									start={start}
									end={end}
								/>
							</div>
						</React.Fragment>
					) : (
						<span sx={styles.spinner}>
							<Spinner animation="border" role="status">
								<span className="sr-only"></span>
							</Spinner>
						</span>
					)}
				</div>
			</Container>
		</PrimaryLayout>
	);
};

const styles = {
	tableCountLabel: {
		marginTop: '10px',
		"@media only screen and (max-width: 768px)": {
			display: 'none'
		},
	},
	emailTemplateSelect: {
		fontSize: '13px'
	},
	floatRight: {
		float: 'right!important'
	},
	spinner: {
		textAlign: "center",
		padding: "20px",
		display: "block",
		".spinner-border": {
			height: "4em",
			width: "4em",
			color: "#00d4c5",
		},
	},
	buttonSpinner: {
		textAlign: "center",
		display: "block",
		".spinner-border": {
			color: "white",
		},
	},
	right: {
		float: 'right'
	},
	container: {
		"@media only screen and (max-width: 768px)": {
			paddingLeft: "10px!important",
			paddingRight: "10px!important",
		},
		wrapper: {
			border: "1px solid #e4e4e4",
			boxShadow: "0 2px 45px 0 rgb(0 0 0 / 6%)",
			transition:
				"background 0.3s,border 0.3s,border-radius 0.3s,box-shadow 0.3s",
			borderRadius: "6px",
			background: "white",
			position: "relative",
			margin: "0 auto",
			"@media only screen and (max-width: 768px)": {
				padding: 20,
			},
		},

		tableWrapper: {
			"@media only screen and (max-width: 1206px)": {
				overflowX: "scroll",
			},
			maxWidth: "1200px",
		},

		footer: {
			display: "unset",
		},

		products: {
			paddingLeft: "10px",
			item: {
				width: "max-content",
			},
		},
		error: {
			margin: "0 auto",
			fontSize: "14px",
		},
		headerWrapper: {
			padding: "40px 30px 0 30px",
			"@media only screen and (max-width: 768px)": {
				padding: "30px 10px",
				margin: "30px auto",
			},
		},
		header: {
			textAlign: "center",
		},
		modal: {
			description: {
				maxHeight: "200px",
				overflowY: "scroll",
			},
			close: {
				position: "absolute",
				right: "3px",
				fontSize: "40px",
				top: "5px",
				cursor: "pointer",
			},
			footer: {
				width: "100%",
				margin: "0 auto",
				display: "block",
			},
			title: {
				fontSize: "17px",
				margin: 0,
			},
		},
	},
	table: {
		marginTop: '20px',
		width: "100%",
		borderCollapse: "collapse",
	},
	checkbox: {
		width: "5%",
		wordWrap: "break-Word",
	},
	id: {
		width: "10%",
		wordWrap: "break-Word",
	},
	date: {
		width: "10%",
		wordWrap: "break-Word",
	},
	balance: {
		width: "20%",
		wordWrap: "break-Word",
	},
	header: {
		textAlign: "center",
	},
	selected: {
		border: "1px solid #e4e4e4",
		boxShadow: "0 2px 45px 0 rgb(0 0 0 / 6%)",
		transition:
			"background 0.3s,border 0.3s,border-radius 0.3s,box-shadow 0.3s",
		padding: "30px 40px 30px 40px",
		borderRadius: "6px",
		background: "white",
		maxWidth: "400px",
		marginTop: "3rem",
		margin: "40 auto",
		position: "relative",
		"@media only screen and (max-width: 768px)": {
			padding: "20px 20px",
			margin: "10px auto",
		},
	},
	selectedcontent: {
		display: "flex",
		justifyContent: "center",
	},
	item: {
		cursor: "pointer",
		"list-style-type": "none",
		textAlign: "left",
		width: "100%",
		border: "3px solid #eeee",
		margin: "6px",

		":hover": {
			opacity: ".9",
		},
		":active": {
			backgroundColour: "#8b0000",
		},
	},
	content: {
		backgroundColor: "white",
		color: "black",
		overflow: "hidden",
		paddingLeft: "18px",

		margin: "6px",

		"&.show": {
			display: "none",
		},
	},
	wrapper: {
		marginTop: "5rem",
		maxWidth: "600px",
		border: "1px solid #eeee",
		padding: "30px 40px 30px 40px",
		borderRadius: "6px",
		boxShadow: "0 2px 4px rgba(41, 41, 41, 0.06)",
		"@media only screen and (max-width: 768px)": {
			padding: "20px 20px",
			margin: "10px auto",
			marginTop: "2rem",
		},
	},

	sectionHeading: {
		marginBottom: "1.5rem",
	},
	buttonDanger: {
		fontSize: "14px",
		color: "white",
		fontWeight: "600",
		padding: "12.5px 25px",
		borderRadius: "10px",
		background: "#DC3545",
		width: "100%",
		textTransform: "uppercase",

		"&:disabled": {
			opacity: 0.6,
			cursor: "not-allowed",
			backgroundColor: "#DC3545",
			borderColor: "#DC3545",
			color: "#878788",
			fontWeight: 500,
		},
		":hover": {
			opacity: ".9",
		},
	},
	modal: {
		header: {
			textAlign: "center",
		},
		close: {
			position: "absolute",
			right: "3px",
			fontSize: "40px",
			top: "5px",
			cursor: "pointer",
		},
		description: {
			maxHeight: '250px',
			overflowY: 'scroll'
		},
		title: {
			fontSize: "17px",
			margin: 0,
		},
		body: {
			lineHeight: "2.5rem",
		},
	},
	filter: {
		color: "#878788",
	},
	tableWrapper: {
		'@media only screen and (max-width: 1206px)': {
			overflowX: 'scroll'
		},
		marginTop: "20px",
		border: "1px solid #eeee",
		padding: "30px 40px 30px 40px",
		borderRadius: "6px",
		boxShadow: "0 2px 4px rgba(41, 41, 41, 0.06)",
		"@media only screen and (max-width: 768px)": {
			padding: "20px 20px",
			margin: "10px auto",
			marginTop: "2rem",
		},
	},
	button: {
		color: "white",
		padding: "5px",
		borderRadius: "4px",
		background: "rgb(35, 179, 163)",
		border: "rgb(35, 179, 163)",
		"&:disabled": {
			opacity: 0.6,
			cursor: "not-allowed",
			backgroundColor: "#00D4C5",
			borderColor: "#00D4C5",
			color: "#878788",
			fontWeight: 500,
		},
		":hover": {
			opacity: ".9",
		},
	},
	buttonConfirm: {
		fontSize: "14px",
		color: "white",
		fontWeight: "600",
		padding: "12.5px 25px",
		borderRadius: "10px",
		background: "#00D4C5",
		width: "100%",
		textTransform: "uppercase",

		"&:disabled": {
			opacity: 0.6,
			cursor: "not-allowed",
			backgroundColor: "#00D4C5",
			borderColor: "#00D4C5",
			color: "#878788",
			fontWeight: 500,
		},
		":hover": {
			opacity: ".9",
		},
	},
	input: {
		searchWrapper: {
			display: "flex",
			alignItems: "center",
			border: "1px solid #E5E5E5",
			borderRadius: "8px",
			textIndent: '5px',
			"@media only screen and (max-width: 768px)": {
				width: "100%",
				display: "block",
			},
			float: 'right'
		},
		searchBar: {
			width: "100%",
			paddingRight: "40px",
			marginLeft: "30px",
			border: "0px",
			borderStyle: "solid",
			boxShadow: "none",
			transition: "border-color 0.2s ease",
			borderColor: "#E4E4E4",
			"&:focus": {
				outline: "none",
				boxShadow: "none",
				borderColor: "darkgray",
			},
			"@media only screen and (max-width: 768px)": {
				width: "80%",
				display: "block",
				padding: ".8rem",
				marginLeft: "24px",
			},
		},
		searchIcon: {
			position: "absolute",
			marginLeft: "8px",
			fontSize: "18px",
			"@media only screen and (max-width: 768px)": {
				fontSize: "22px",
				position: "absolute",
				marginLeft: "8px",
				marginTop: "0.8rem",
			},
		},
		searchButton: {
			backgroundColor: "#00d4c5",
			fontSize: "14px",
			fontWeight: "600",
			textTransform: "uppercase",
			borderRadius: "0    6px      6px           0",
			"@media only screen and (max-width: 768px)": {
				width: "100%",
				borderRadius: "0 0  6px 6px",
			},
		},
	},
	pagination: {
		justifyContent: "center",
		'.pagination': {
			float: 'right!important'
		}
	},
	exportButton: {
		backgroundColor: "#00d4c5",
		fontSize: "14px",
		fontWeight: "600",
		textTransform: "uppercase",
		borderRadius: "6px",
	},
	filterButton: {
		backgroundColor: "#00d4c5",
		fontSize: "14px",
		fontWeight: "600",
		textTransform: "uppercase",
		borderRadius: "6px",
		marginTop: '21px',
		minHeight: '0!important'
	},
};

export default ProductVatPage;
